import React from 'react';
import { ucFirst } from '../../utils/caseconverters';
import PropTypes, { object } from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';
import styles from './Button.module.scss';

const Button = ({
    buttonType = 'button',
    text = '',
    type = 'primary',
    value = '',
    icon = '',
    iconSize = '',
    iconDirection = '',
    iconDimensions = {},
    link = {},
    modifiers = [],
    disabled = false,
    styledDisabled = false,
    onClick,
}) => {
    const hasLink = link && link.href;
    text = text || link.title;

    const classes = classNames(
        styles['Button'],
        styles['Button--' + ucFirst(type)],
        modifiers.map(
            (modifier) => styles['Button--' + ucFirst(modifier)] + ' '
        ),
        {
            [styles['Button--Disabled']]: styledDisabled,
        }
    );

    const innerContent = (
        <span
            className={classNames(styles['Button__Inner'], {
                [styles['Button__Inner--' + ucFirst(iconDirection)]]:
                    iconDirection,
            })}>
            {text}
            {!!icon && (
                <Icon type={icon} dimensions={iconDimensions} size={iconSize} />
            )}
        </span>
    );

    const buttonAttributes = {
        className: classes,
        onClick: onClick,
    };

    const buttonText = icon ? innerContent : text;

    return hasLink ? (
        <a {...buttonAttributes} href={link.href} target={link.target}>
            {buttonText}
        </a>
    ) : (
        <button
            {...buttonAttributes}
            type={buttonType}
            value={value}
            disabled={disabled}>
            {buttonText}
        </button>
    );
};

Button.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'profile', 'blue']),
    buttonType: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
        target: PropTypes.string,
    }),
    srText: PropTypes.string,
    icon: PropTypes.string,
    iconDirection: PropTypes.string,
    iconSize: PropTypes.string,
    disabled: PropTypes.bool,
    modifiers: PropTypes.array,
    onClick: PropTypes.func,
};

export default Button;
